import { Grid } from "@mui/material";

import backgroundImage from "../../assets/login-background.jpg";

const LoginImage = () => {
  return (
    <Grid
      item
      xs={false}
      sm={false}
      md={9}
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        opacity: 0.9
      }}
    />
  );
};

export default LoginImage;
